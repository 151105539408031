import React, { useRef } from 'react';
import classNames from 'classnames';
import { useCart } from '@/modules/shared/hooks';
import { Autoplay, Grid, type Swiper as SwiperRef } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonUI, ProductCard, SkeletonImage, SkeletonLine } from '@/components';
import { useRouter } from 'next/router';

export interface DataCard {
	id: number;
	img: string;
	url: string;
	title: string;
	price: number;
	split: number;
}

export interface GroupCardProps {
	className?: string;
	titleClassName?: string;
	extraBanner?: string;
	isMobile?: boolean;
	loading?: boolean;
	extraContent?: React.ReactNode;
	row?: number;
	url?: string;
	title: string;
	data: DataCard[];
}

const sliderSettings = {
	320: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	680: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	1024: {
		slidesPerView: 5,
		// spaceBetween: 20,
	},
};

const GroupCard: React.FC<GroupCardProps> = ({
	className,
	titleClassName,
	extraContent,
	extraBanner,
	loading,
	isMobile,
	row,
	url,
	title,
	data,
}) => {
	const swiperRef = useRef<SwiperRef>();
	const { addCart } = useCart();
	const router = useRouter();

	return (
		<div
			className={classNames(
				'rounded-none lg:rounded-xl p-1 lg:p-medium overflow-hidden lg:my-[30px] relative',
				className,
			)}
		>
			{extraBanner ? (
				<>
					<div className='absolute w-full top-0 left-0 wrapper h-[120px]'>
						<img className='w-full' src={extraBanner} />
					</div>
					<div className={classNames('pb-[90px]')}></div>
				</>
			) : (
				<h3
					className={classNames([
						'font-bold flex justify-center lg:justify-center text-large mt-4 ml-2 lg:ml-0 lg:mt-0 lg:text-large-4x uppercase',
						titleClassName,
					])}
				>
					{title}
				</h3>
			)}

			{extraContent}

			{!isMobile ? (
				<div className='relative'>
					{!loading ? (
						<button className='swiper-btn-prev' onClick={() => swiperRef.current?.slidePrev()}>
							<ChevronLeftIcon className='text-primary' width={24} />
						</button>
					) : null}

					<Swiper
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper;
						}}
						style={{ height: 'auto', marginTop: 28 }}
						breakpoints={sliderSettings}
						grid={{
							rows: row ?? 1,
						}}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						modules={[Grid, Autoplay]}
						className='mySwiper'
						loop={true}
					>
						{data.length ? (
							<div className='custom_wrapper'>
								{data.map((item, index) => {
									const { id, url, img, title, price, split } = item;
									// const { variants, images, name, id } = slideContent ?? {};

									// const priceSplit = variants
									// 	.map((item: any) => {
									// 		return {
									// 			currentPrice: item.salePrice,
									// 			split: item.revenueSplit,
									// 		};
									// 	})
									// 	.sort((a: any, b: any) => a.currentPrice - b.currentPrice);

									return (
										<SwiperSlide
											className={classNames(['slide-product p-[20px]'])}
											key={index}
											virtualIndex={index}
										>
											<div className='custom_item w-full h-full rounded overflow-hidden'>
												<ProductCard
													isCenter
													key={index}
													url={url}
													image={img}
													title={title}
													price={price}
													split={split}
													onClick={() => addCart(id)}
												/>
											</div>
										</SwiperSlide>
									);
								})}
							</div>
						) : (
							<div className='grid grid-cols-5 gap-5'>
								{[...new Array(5)]?.map((k, i) => (
									<div key={i} className='slide-product bg-base-100'>
										<div className='w-full h-full'>
											<div className='w-full pb-4 aspect-square relative overflow-hidden'>
												<SkeletonImage />
											</div>
											<div className='space-y-[16px] text-left py-9 px-5'>
												<SkeletonLine />
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</Swiper>
					{!loading ? (
						<button className='swiper-btn-next' onClick={() => swiperRef.current?.slideNext()}>
							<ChevronRightIcon className='text-primary' width={24} />
						</button>
					) : null}
				</div>
			) : (
				<div className='relative mt-[20px]'>
					{/* <div className='flex flex-col flex-wrap -m-[4px] overflow-x-auto h-[550px]'>
						{memoExtraProduct?.list?.length
							? memoExtraProduct.list?.map((slideContent: any, index: number) => {
									const { variants, images, name, id } = slideContent ?? {};

									const priceSplit = variants
										.map((item: any) => {
											return {
												currentPrice: item.salePrice,
												split: item.revenueSplit,
											};
										})
										.sort((a: any, b: any) => a.currentPrice - b.currentPrice);
									return (
										<div key={index} className='p-[4px] h-[50%] w-[38%]'>
											<ProductCard
												className='h-full'
												key={index}
												url={`/chi-tiet/${slideContent?.id}`}
												image={images?.[0] ?? ''}
												title={name}
												price={priceSplit[0]?.currentPrice}
												split={priceSplit[0] && priceSplit[0].split}
												onClick={() => addCart(id)}
											/>
										</div>
									);
							  })
							: [...new Array(6)]?.map((k, i) => (
									<div key={i} className='p-[8px] h-[50%] w-[42%]'>
										<div className='slide-product bg-base-100'>
											<div className='w-full h-full'>
												<div className='w-full pb-4 aspect-square relative overflow-hidden'>
													<SkeletonImage />
												</div>
												<div className='space-y-[16px] text-left py-9 px-5'>
													<SkeletonLine nonLineMain />
												</div>
											</div>
										</div>
									</div>
							  ))}
					</div> */}
				</div>
			)}

			<ButtonUI
				className='bg-base-100 w-full lg:w-max capitalize text-primary mx-auto flex justify-center text-base lg:text-medium hover:text-base-100 mt-4 mb-4 lg:mb-0'
				text='Xem tất cả sản phẩm'
				onClick={() => {
					router.push(url ?? `/san-pham`);
				}}
			/>
		</div>
	);
};

export default GroupCard;
