import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';

export interface UtilityItem {
	bg: string;
	url: string;
	title: string;
	content: React.ReactNode;
	icon: any;
}

export interface UtilityCardProps {
	className?: string;
	data: UtilityItem[];
}

const UtilityCard: React.FC<UtilityCardProps> = ({ className, data }) => {
	return (
		<div className={classNames('bg-white p-4 space-y-4 rounded-lg', className)}>
			<h3 className='uppercase text-[22px] font-bold text-lightBlack'>Dịch vụ tiện ích</h3>
			<div className='flex -m-2'>
				{data.map((item, index) => (
					<Link href={item.url} key={index} target='_blank' className='p-2 w-1/4'>
						<div className={classNames('p-3 rounded flex space-x-2 h-full', item.bg)}>
							<div className='shrink-0'>{item.icon}</div>
							<div className='space-y-2'>
								<p className='text-[20px]'>{item.title}</p>
								{item.content}
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};

export default UtilityCard;
