import { ReactElement } from 'react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

import { DeviceType, ENUM_DEFAULT_PARAMS_PAGINATION } from '@/constant';
// import required modules
import { DefaultLayout } from '@/layouts';
import { HomePageProps } from '@/models';
import HomePage from '@/modules/home';
import HomePageMobile from '@/modules/home/mobile';
// import {
// 	fetchListProductDiscount,
// 	fetchListProductNew,
// 	fetchListProductPopularity,
// } from '@/services';
import { wrapper } from '@/store';

const Home = ({
	dataCategory,
	dataBanner,
	dataBrand,
	deviceType,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
	const isMobile = deviceType === DeviceType.MOBILE;

	return !isMobile ? (
		<HomePage dataCategory={dataCategory} dataBanner={dataBanner} dataBrand={dataBrand} />
	) : (
		<HomePageMobile dataCategory={dataCategory} dataBanner={dataBanner} dataBrand={dataBrand} />
	);
};

export default Home;

export const getServerSideProps: GetServerSideProps<HomePageProps, any> =
	wrapper.getServerSideProps((store) => async (context) => {
		const { res } = context;
		res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=59');

		// const promises = [
		// 	// fetchListProductNew({
		// 	// 	page: ENUM_DEFAULT_PARAMS_PAGINATION.Page,
		// 	// 	pageSize: ENUM_DEFAULT_PARAMS_PAGINATION.PageSize,
		// 	// }),
		// 	// fetchListProductDiscount({
		// 	// 	page: ENUM_DEFAULT_PARAMS_PAGINATION.Page,
		// 	// 	pageSize: ENUM_DEFAULT_PARAMS_PAGINATION.PageSize,
		// 	// }),
		// 	// fetchListProductPopularity({
		// 	// 	page: ENUM_DEFAULT_PARAMS_PAGINATION.Page,
		// 	// 	pageSize: ENUM_DEFAULT_PARAMS_PAGINATION.PageSize,
		// 	// }),
		// ];

		// const results = await Promise.allSettled(promises);
		// const [responseProductNew, responseProductDiscount, responseProductPopularity] = results as any;

		const { common } = store.getState();

		return {
			props: {
				dataCategory: common?.dataCategoryTree?.data?.data?.rows ?? null,
				// dataProduct: {
				// 	// popularity: responseProductPopularity.value.data.list ?? [],
				// 	// new: responseProductNew.value.data.list ?? [],
				// 	// discount: responseProductDiscount?.value ?? [],
				// 	// popularity: responseProductPopularity?.value ?? [],
				// },
				dataBrand: common?.dataBrand?.data ?? null,
				dataBanner: common?.dataBanner?.data ?? [],
				deviceType: common?.device,
			},
		};
	});

Home.getLayout = (page: ReactElement) => <DefaultLayout>{page}</DefaultLayout>;
