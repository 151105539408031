import React from 'react';

import useSWR from 'swr';
import { request } from '@/utils';
import { useRouter } from 'next/router';

// import required modules
import { ProductBrandCard, ProductWrapperCard } from '@/components';
import { HomePageProps } from '@/models';

const HomePageMobile: React.FC<HomePageProps> = ({ dataCategory, dataProduct, dataBrand }) => {
	const router = useRouter();

	const fetcher = async (url: any) => {
		return await request({
			url,
		}).then((data) => {
			return data.data;
		});
	};

	const handleCategory = (id: number) => {
		router.push(`/san-pham?category=${id}`);
	};

	const { data: popularityData } = useSWR('/products?category=1', fetcher);
	const { data: dicountData } = useSWR('/products?category=739', fetcher);
	const { data: newData } = useSWR('/products?category=286', fetcher);

	const dataSend = {
		popular: popularityData,
		discount: dicountData,
		new: newData,
	};

	return (
		<React.Fragment>
			<div className='relative overflow-hidden'>
				<ProductBrandCard />
				<ProductWrapperCard
					title='sản phẩm ưa chuộng'
					color='primary'
					row={2}
					isLoadMore={true}
					extraProduct={dataSend}
					isMobile
					typeProduct='POPULAR'
				/>

				<ProductWrapperCard
					title='SẢN PHẨM GIẢM GIÁ'
					color='danger'
					isLoadMore={true}
					extraProduct={dataSend}
					isMobile
					typeProduct='DISCOUNT'
				/>

				<ProductWrapperCard
					title='SẢN PHẨM MỚI'
					color='lightBlue'
					isLoadMore={true}
					extraProduct={dataSend}
					isMobile
					typeProduct='NEW'
				/>
			</div>
		</React.Fragment>
	);
};

export default HomePageMobile;
