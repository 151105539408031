import classNames from 'classnames';
import React from 'react';
import { AdvertiseItem } from '../../type';
import Link from 'next/link';

export interface DealCardProps {
	className?: string;
	title: string;
	data: AdvertiseItem[];
}

const DealCard: React.FC<DealCardProps> = ({ className, title, data }) => {
	return (
		<div className={classNames('p-4 rounded-lg space-y-4', className)}>
			<h3 className='uppercase text-[22px] font-bold text-lightBlack'>{title}</h3>
			<div className='flex -m-3'>
				{data.map((item, index) => {
					return (
						<div className='p-3 w-1/3' key={index}>
							<Link
								href={item.url}
								target='_blank'
								className='block w-full rounded overflow-hidden'
							>
								<img src={item.img} alt='' />
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DealCard;
