import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

export interface NewsItem {
	img: string;
	subString: string;
	url: string;
}

export interface NewsCardProps {
	className?: string;
	data: NewsItem[];
}

const NewsCard: React.FC<NewsCardProps> = ({ className, data }) => {
	return (
		<div className={classNames('p-4 bg-white rounded-lg space-y-4', className)}>
			<h3 className='uppercase text-[22px] font-bold text-lightBlack'>Tin tức</h3>
			<div className='flex -m-2'>
				{data.map((item, index) => {
					return (
						<div key={index} className='w-1/3 p-2'>
							<Link href={item.url} target='_blank' className='space-y-2'>
								<div className='w-full rounded-md overflow-hidden'>
									<img src={item.img} alt='' />
								</div>
								<p className='line-clamp-2'>{item.subString}</p>
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default NewsCard;
