import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { type Swiper as SwiperRef } from 'swiper';
import { GrFan } from 'react-icons/gr';
import { PiPaintBrushHouseholdFill } from 'react-icons/pi';
import { FaPlug } from 'react-icons/fa';
import { CiShoppingTag } from 'react-icons/ci';
// import required modules
import {
	Banner,
	ImageCustom,
	ProductBrandCard,
	ProductWrapperCard,
	TypeServicesCard,
} from '@/components';
import { SkeletonImage, SkeletonLine } from '@/components/Skeletons';
import { useIsomorphicLayoutEffect, useWindowDimensions } from '@/hooks';
import { HomePageProps, IProductItem } from '@/models';
import { fetchListProductPopularity } from '@/services';
import { ENUM_DEFAULT_PARAMS_PAGINATION } from '@/constant';
import useSWR from 'swr';
import { request } from '@/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import {
	BRANDS,
	DEALS,
	IMAGES_EXTRACATEGORY,
	NEWS,
	SALE_PROGRAM,
	SENKO_PRODUCTS,
	SLIDER_ITEMS,
	TRENDING,
} from './constant';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import * as HomeCard from './components/homeCard';
import { DataCard } from './components/homeCard/GroupCard';
import { RoutConfig } from '@/constant/routes';

// interface IActiveCategory {
// 	id: string | null;
// 	isActive: boolean;
// }

const sliderSettings = {
	320: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	680: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	1024: {
		slidesPerView: 5,
		// spaceBetween: 20,
	},
};

const HomePage: React.FC<HomePageProps> = ({ dataCategory, dataBanner, dataBrand }) => {
	const reff = useRef<SwiperRef>();
	const extraSenkoRef = useRef<SwiperRef>();
	const router = useRouter();
	const { width, height } = useWindowDimensions();
	const refContainer = useRef<HTMLDivElement>(null);
	const refBannerFixed = useRef<HTMLDivElement>(null);
	const [isActiveBannerFixed, setIsActiveBannerFixed] = useState<boolean>(false);

	const handleScroll = () => {
		if (refContainer.current?.offsetTop && height) {
			window.pageYOffset >= refContainer.current?.offsetTop + height - 100 &&
			window.pageYOffset <= refContainer.current?.clientHeight + 280
				? setIsActiveBannerFixed(true)
				: setIsActiveBannerFixed(false);
		}
	};

	useIsomorphicLayoutEffect(() => {
		height && window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [height]);

	const fetcher = async (url: any) => {
		return await request({
			url,
		}).then((data) => {
			return data.data;
		});
	};

	const handleCategory = (id: number) => {
		router.push(`/san-pham?category=${id}`);
	};

	const { data: popularityData } = useSWR('/products?category=1', fetcher);
	const { data: dicountData } = useSWR('/products?category=739', fetcher);
	const { data: newData } = useSWR('/products?category=286', fetcher);
	const { data: senkoData } = useSWR('/products?category=206', fetcher);

	const percentWidthRatio = (w: number, currentWidthContainer: number): number =>
		(100 - (currentWidthContainer / (w >= 1920 ? 1750 : w)) * 100) / 2; //100% - (max width content / width * 100%) / 2

	const dataSend = {
		popular: popularityData,
		discount: dicountData,
		new: newData,
	};

	const senkoDataConfig = React.useMemo<DataCard[]>(() => {
		if (senkoData && senkoData.list) {
			const list = senkoData.list as IProductItem[];

			return list.map((item) => {
				const { variants, images, name, id } = item ?? {};

				const priceSplit = variants
					.map((item: any) => {
						return {
							currentPrice: item.salePrice,
							split: item.revenueSplit,
						};
					})
					.sort((a: any, b: any) => a.currentPrice - b.currentPrice);
				return {
					id,
					title: name,
					url: `/chi-tiet/${item?.id}`,
					img: images?.[0] ?? '',
					price: priceSplit[0]?.currentPrice,
					split: priceSplit[0] && priceSplit[0].split,
				};
			});
		}

		return [];
	}, [senkoData]);

	const utilityData = React.useMemo<HomeCard.UtilityItem[]>(() => {
		return [
			{
				bg: 'bg-[#dceeff]',
				url: `${RoutConfig.VN.detail}/3985`,
				title: 'Mua quạt mini',
				content: (
					<p className='text-[16px] text-lightBlack'>
						<span className='font-bold text-[18px] text-[#ff0b0b]'>Giảm 5%</span> cho đơn hàng từ
						100.000 trở lên
					</p>
				),
				icon: <GrFan className='mt-[4px] text-[#66abec] text-[22px]' />,
			},
			{
				bg: 'bg-[#fef5cf]',
				url: `${RoutConfig.VN.previewProduct}?category=2001`,
				title: 'Mua sơn dầu',
				content: (
					<p className='text-[16px] text-lightBlack'>
						Tặng ngay 1 cây lăn sơn 6cm Việt Mỹ khi mua sơn
					</p>
				),
				icon: <PiPaintBrushHouseholdFill className='mt-[4px] text-[#e6ce65] text-[22px]' />,
			},
			{
				bg: 'bg-[#ffefdb]',
				url: `${RoutConfig.VN.previewProduct}?topCategory=1&category=117&subCategory=113&childCategory=117`,
				title: 'Sắm ổ điện',
				content: (
					<p className='text-[16px] text-lightBlack'>
						Tặng kèm phích cắm điện cao cấp{' '}
						<span className='font-bold text-[18px] text-[#ff0b0b]'>Vinakip</span>
					</p>
				),
				icon: <FaPlug className='mt-[4px] text-[#ff0b0b] text-[22px]' />,
			},
			{
				bg: 'bg-[#e1fecf]',
				url: `${RoutConfig.VN.previewProduct}?ProductName=DEAL`,
				title: 'Deals hấp dẫn',
				content: <p className='text-[16px] text-lightBlack'>Tặng kèm quà hơn 59k cho các deals</p>,
				icon: <CiShoppingTag className='mt-[4px] text-[#86d952] text-[22px]' />,
			},
		];
	}, []);

	return (
		<React.Fragment>
			<Banner dataBanner={SLIDER_ITEMS} />
			<div className='relative pb-[60px] overflow-hidden'>
				<div
					ref={refBannerFixed}
					style={{
						width: `calc(${percentWidthRatio(
							width ?? 1,
							refContainer.current?.clientWidth ?? 1,
						)}% - 24px)`,
						height: '100%',
						maxHeight: '70%',
						left: `calc(${
							(100 - (refContainer.current?.clientWidth! / width!) * 100) / 2 -
							percentWidthRatio(width ?? 1, refContainer.current?.clientWidth ?? 1)
						}%)`,
						opacity: isActiveBannerFixed ? '1' : '0',
					}}
					className={'fixed top-[8rem] transition-all duration-200 overflow-hidden rounded-[15px]'}
				>
					<img src='/static/images/decor/decor_1.png' className='w-full' />
				</div>
				<section>
					<div className='custom-container' ref={refContainer}>
						<div className='mt-[30px]'>
							<div className='flex items-center -m-4'>
								{SALE_PROGRAM.map((item, index) => {
									return (
										<div key={index} className='w-1/4 p-4'>
											<HomeCard.SaleCard {...item} />
										</div>
									);
								})}
							</div>
						</div>

						<div className='decor_banner mt-[30px]'>
							<Link href={'https://www.nppngaliem.com/tin_tuc/05'} target='_blank'>
								<img
									src='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720175186/product_banner/decor_banner_gu617u.png'
									alt='appollo silicon'
								/>
							</Link>
						</div>

						<ProductWrapperCard
							extraBanner='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720167477/product_banner/favorite_product_nddrza.png'
							title='sản phẩm ưa chuộng'
							color='primary'
							typeProduct='POPULAR'
							row={2}
							// isLoading={!dataProduct?.popularity?.data?.length}
							isLoadMore={true}
							extraProduct={dataSend}
							extraCategory={
								<div className='mt-[35px] mb-[-24px] relative'>
									<button className='swiper-btn-prev' onClick={() => reff.current?.slidePrev()}>
										<ChevronLeftIcon className='text-primary' width={24} />
									</button>
									<Swiper
										onBeforeInit={(swiper) => {
											reff.current = swiper;
										}}
										centeredSlides={true}
										breakpoints={sliderSettings}
										loop={true}
									>
										{dataCategory?.length
											? dataCategory?.map((data, index) => {
													const icon =
														IMAGES_EXTRACATEGORY.find((item) => item.id === data.id)?.image ??
														'/static/images/static_category.svg';

													return (
														<SwiperSlide
															className={classNames(['custom-extra-category p-[6px] 2xl:p-[10px]'])}
															key={index}
															virtualIndex={index}
														>
															<div key={index} className='w-full' style={{ gridColumn: 'auto' }}>
																<TypeServicesCard
																	className='font-bold'
																	name={data.name}
																	icon={icon}
																	onClick={() =>
																		// setActiveCategory({ id, isActive: !isActiveCategory.isActive })
																		handleCategory(data.id)
																	}
																/>
															</div>
														</SwiperSlide>
													);
											  })
											: [...new Array(5)]?.map((_, i) => (
													<div key={i} className='row-[1]' style={{ gridColumn: 'auto' }}>
														<div className='py-5 px-2 rounded-small bg-base-100 flex items-center overflow-hidden gap-3'>
															<div className='max-w-[30%] flex-[0_0_30%] flex aspect-square rounded-full overflow-hidden'>
																<SkeletonImage />
															</div>
															<div className='max-w-[60%] flex-[0_0_60%]'>
																<SkeletonLine line={4} nonLineMain />
															</div>
														</div>
													</div>
											  ))}
									</Swiper>
									<button className='swiper-btn-next' onClick={() => reff.current?.slideNext()}>
										<ChevronRightIcon className='text-primary' width={24} />
									</button>
								</div>
							}
						/>

						<ProductWrapperCard
							extraBanner='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720168059/product_banner/sale_product_wglo8j.png'
							title='SẢN PHẨM GIẢM GIÁ'
							color='danger'
							typeProduct='DISCOUNT'
							row={2}
							// isLoading={!dataProduct?.discount?.data?.length}
							isLoadMore={true}
							extraProduct={dataSend}
						/>

						<ProductWrapperCard
							extraBanner='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720168079/product_banner/new_product_xkask7.png'
							title='SẢN PHẨM MỚI'
							color='lightBlue'
							typeProduct='NEW'
							row={2}
							// isLoading={!dataProduct?.new?.data?.length}
							isLoadMore={true}
							extraProduct={dataSend}
						/>

						<HomeCard.GroupCard
							className='bg-lightBlue'
							titleClassName='text-white xl:text-[44px]'
							title='TUẦN LỄ QUẠT SENKO - GIẢM ĐẾN 1 TRIỆU'
							url='/san-pham?category=206&topCategory=1&subCategory=206'
							data={senkoDataConfig}
							extraContent={
								<div className='mt-[35px] mb-[-24px] relative'>
									<button
										className='swiper-btn-prev'
										onClick={() => extraSenkoRef.current?.slidePrev()}
									>
										<ChevronLeftIcon className='text-primary' width={24} />
									</button>
									<Swiper
										onBeforeInit={(swiper) => {
											extraSenkoRef.current = swiper;
										}}
										slidesPerView={3}
										spaceBetween={20}
										loop={true}
									>
										{SENKO_PRODUCTS?.length
											? SENKO_PRODUCTS?.map((data, index) => {
													return (
														<SwiperSlide key={index} virtualIndex={index}>
															<Link href={data.url} className='rounded-lg overflow-hidden'>
																<img className='w-full' src={data.img} alt='' />
															</Link>
														</SwiperSlide>
													);
											  })
											: [...new Array(5)]?.map((_, i) => (
													<div key={i} className='row-[1]' style={{ gridColumn: 'auto' }}>
														<div className='py-5 px-2 rounded-small bg-base-100 flex items-center overflow-hidden gap-3'>
															<div className='max-w-[30%] flex-[0_0_30%] flex aspect-square rounded-full overflow-hidden'>
																<SkeletonImage />
															</div>
															<div className='max-w-[60%] flex-[0_0_60%]'>
																<SkeletonLine line={4} nonLineMain />
															</div>
														</div>
													</div>
											  ))}
									</Swiper>
									<button
										className='swiper-btn-next'
										onClick={() => extraSenkoRef.current?.slideNext()}
									>
										<ChevronRightIcon className='text-primary' width={24} />
									</button>
								</div>
							}
						/>

						<HomeCard.TrendingCard
							className='trending-wrapper'
							title='Xu hướng mua sắm'
							titleClassName='text-lightBlack'
							data={TRENDING}
						/>

						<ProductBrandCard className='mt-[30px]' />

						<div className='space-y-4 mt-[30px]'>
							<h3 className='uppercase text-lightBlack text-[22px] font-bold'>
								Khuyến mãi chỉ có trên online
							</h3>
							<div className='w-full'>
								<img
									src='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720268621/sale_program/NH%E1%BA%ACN_NGAY_M%C3%83_GI%E1%BA%A2M_GI%C3%81_10_T%E1%BB%90I_%C4%90A_500.000%C4%90_pa6vyk.png'
									alt=''
								/>
							</div>
						</div>

						<HomeCard.UtilityCard className='mt-[30px]' data={utilityData} />

						<div className='mt-[30px] flex space-x-3'>
							<div className='w-full'>
								<HomeCard.NewsCard data={NEWS} />
							</div>
							<Link
								href={`${RoutConfig.VN.previewProduct}?category=848&topCategory=822&subCategory=848`}
								className='h-[350px] rounded-lg overflow-hidden'
							>
								<img
									className='h-full'
									src='https://res.cloudinary.com/dfyg1ymat/image/upload/v1720275799/sale_program/NG%C3%80NH_H%C3%80NG_%C4%90%E1%BB%92_GIA_D%E1%BB%A4NG_e26mun.png'
								/>
							</Link>
						</div>

						<HomeCard.DealCard
							className='mt-[30px]'
							title='Chuyên trang thương hiệu'
							data={BRANDS}
						/>

						<HomeCard.DealCard className='mt-[30px]' title='Chuỗi deal mới' data={DEALS} />
					</div>
				</section>
				<div
					ref={refBannerFixed}
					style={{
						width: `calc(${percentWidthRatio(
							width ?? 1,
							refContainer.current?.clientWidth ?? 1,
						)}% - 24px)`,
						height: '100%',
						maxHeight: '70%',
						right: `calc(${
							(100 - (refContainer.current?.clientWidth! / width!) * 100) / 2 -
							percentWidthRatio(width ?? 1, refContainer.current?.clientWidth ?? 1)
						}%)`,
						opacity: isActiveBannerFixed ? '1' : '0',
					}}
					className={
						'fixed transition-all duration-200 aspect-square right-0 top-[8rem] overflow-hidden rounded-[15px]'
					}
				>
					<img src='/static/images/decor/decor_2.png' className='w-full' />
				</div>
			</div>
		</React.Fragment>
	);
};

export default HomePage;
