import { useAppDispatch, useAppSelector } from '@/hooks';
import { commonActions, commonSelectors } from '@/store/reducers/commonSlice';
import {
	DocumentTextIcon,
	HomeIcon,
	InformationCircleIcon,
	NewspaperIcon,
	ShoppingCartIcon,
	TruckIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Head } from 'components';
import { LayoutProps } from 'models';
import dynamic from 'next/dynamic';
import Link from 'next/link';

const DynamicHeaderV2 = dynamic(() => import('@/layouts/header/HeaderV2'), {
	ssr: false,
});
// const DynamicHeader = dynamic(() => import('@/components/Header'), {
// 	ssr: false,
// });

const DynamicFooter = dynamic(() => import('@/components/Footer'), {
	ssr: false,
});

const DefaultLayout = ({ children, forceTitleRender, seoProps }: LayoutProps) => {
	const { dataCategory, isOpenMenuDrawer, isNonScrollBody } = useAppSelector(commonSelectors);
	const { dataCategoryTree } = useAppSelector(commonSelectors);

	const stateCategory = dataCategoryTree?.data?.data?.rows ?? [];
	// const stateCategory = dataCategory?.data?.data;

	const dispatch = useAppDispatch();

	const MENU_NAVBAR = [
		{
			title: 'Trang chủ',
			asLink: '/',
			link: '/home',
			icon: <HomeIcon width={20} />,
		},
		{
			link: '',
			title: 'Danh mục sản phẩm',
			render: (
				<ul className='max-h-[350px] overflow-y-auto'>
					{stateCategory?.length &&
						stateCategory?.map((el: any, i: any) => (
							<li key={i} className='pl-[30px]'>
								<Link
									href={`/san-pham?category=${el?.id}`}
									className={'text-small hover:rounded-[5px]'}
								>
									{el?.name}
								</Link>
							</li>
						))}
				</ul>
			),
			icon: <DocumentTextIcon width={20} />,
		},
		{
			title: 'Giới thiệu',
			asLink: '/gioi-thieu',
			link: '/about-us',
			icon: <InformationCircleIcon width={20} />,
		},
		{
			title: 'Tin tức',
			asLink: '/tin-tuc',
			link: '/blog',
			icon: <NewspaperIcon width={20} />,
		},
		{
			title: 'Giỏ hàng',
			asLink: '/gio-hang',
			link: '/cart',
			icon: <ShoppingCartIcon width={20} />,
		},
		{
			title: 'Kiểm tra đơn hàng',
			asLink: '/kiem-tra-don-hang',
			link: '/check-order',
			icon: <TruckIcon width={20} />,
		},
	];

	return (
		<div
			className={classNames([
				isOpenMenuDrawer && 'overflowHidden',
				isNonScrollBody && 'overflowHidden',
			])}
		>
			<Head {...seoProps} active={forceTitleRender}></Head>
			<div className={'relative block'}>
				<div
					className={classNames([
						'absolute animation-300 inset-0 z-30 !bg-black/25',
						isOpenMenuDrawer ? 'opacity-100 visible' : 'opacity-0 invisible',
					])}
					onClick={() => dispatch(commonActions.setOpenMenuDrawer(false))}
				>
					{''}
				</div>
				<div
					className={classNames([
						'absolute animation-300 bg-base-100 z-[201] h-screen',
						isOpenMenuDrawer ? 'right-0 block' : '-right-[100%] hidden',
					])}
				>
					<ul className='menu w-80 bg-base-100 text-primary p-4'>
						{MENU_NAVBAR?.map((el, index) => {
							if (el.render) {
								return (
									<div key={index}>
										<li key={index} className='flex flex-col space-y-2'>
											<div
												className={
													'text-medium w-full font-normal bordered hover:bg-primary hover:text-base-100'
												}
											>
												{el?.icon} {el?.title}
											</div>
										</li>
										{el.render}
									</div>
								);
							}

							return (
								<li key={index}>
									<Link
										as={el?.asLink}
										href={el?.link}
										className={
											'text-medium w-full font-normal bordered hover:bg-primary hover:text-base-100'
										}
									>
										{el?.icon} {el?.title}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
				{/* <DynamicHeader /> */}
				<DynamicHeaderV2 />
				<main className='pt-[var(--header-mobile-height)] xl:pt-[var(--header-height)]'>
					{children}
				</main>
				<DynamicFooter />
			</div>
		</div>
	);
};

export default DefaultLayout;
