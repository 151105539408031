import classNames from 'classnames';
import React from 'react';
import { AdvertiseItem } from '../../type';
import Link from 'next/link';

export interface TrendingCardProps {
	className?: string;
	titleClassName?: string;
	data: AdvertiseItem[];
	title: string;
}

const TrendingCard: React.FC<TrendingCardProps> = ({ className, titleClassName, title, data }) => {
	return (
		<div className={classNames('rounded-lg p-4 space-y-4', className)}>
			<h3 className={classNames('uppercase text-[22px] font-bold', titleClassName)}>{title}</h3>
			<div className='flex -m-2 flex-wrap'>
				{data.map((item, index) => {
					return (
						<div key={index} className='w-1/2 lg:w-1/3 xl:w-1/4 p-2'>
							<Link href={item.url} className='block w-full rounded-lg overflow-hidden'>
								<img className='w-full' src={item.img} alt='' />
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default TrendingCard;
