import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { RoutConfig } from '@/constant/routes';

export interface SaleCardProps {
	className?: string;
	title: React.ReactNode;
	img: string;
	query: string;
}

const SaleCard: React.FC<SaleCardProps> = ({ className, img, title, query }) => {
	return (
		<Link
			href={`${RoutConfig.VN.previewProduct}?${query}`}
			className={classNames(
				'bg-white rounded-lg p-2 flex space-x-4 justify-center items-center flex-col xl:flex-row',
				className,
			)}
		>
			<div className='shrink-0 w-[60px] h-[60px] xl:w-[80px] xl:h-[80px] rounded-full flex items-center justify-center overflow-hidden'>
				<img src={img} className='object-fill w-full' />
			</div>
			<p className='line-clamp-2 text-[14px] xl:text-[20px] text-primary font-medium capitalize'>
				{title}
			</p>
		</Link>
	);
};

export default SaleCard;
